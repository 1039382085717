import { Component } from '@angular/core';
import {ActiveUserService} from "../../services/active-user.service";
import {SnackbarService} from "../../services/snackbar.service";
import {IApiErrorResponse} from "../../interfaces/error/IApiErrorResponse";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {ITestEmails} from "../../interfaces/ITestEmails";
import {EmailTestingService} from "../../services/email-testing.service";
import {GameService} from "../../services/game.service";
import {MatButton} from "@angular/material/button";
import {SentryTestingService} from "../../services/sentry-testing.service";
import {ITestSentryException} from "../../interfaces/ITestSentryException";

@Component({
  selector: 'app-testing-page',
  standalone: true,
  templateUrl: './testing-page.component.html',
  imports: [
    MatButton
  ],
  styleUrls: ['./testing-page.component.css']
})
export class TestingPageComponent {
  public activeAdmin = this.activeUserService.activeUser();
  public activeGame = this.gameService.activeGame();
  constructor(private emailTestingService: EmailTestingService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private sentryTestingService: SentryTestingService) {
  }

  public generateTestEmails(){
    const testEmailsRequest: ITestEmails = {
      AdminId: this.activeAdmin.Id,
      GameId: this.gameService.activeGame().Id
    }
    this.emailTestingService.sendTestEmails(testEmailsRequest).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar('Emails were successfully sent');
        },
      error: (res: IApiErrorResponse) => {
          if (res.Error?.ClientErrorMessages?.length > 0) {
            this.errorHandlingService.displayPageLevelErrorMessage(res, res.Error.ClientErrorMessages.toString())
          } else {
            this.errorHandlingService.displayPageLevelErrorMessage(res);
          }
      }
    }
    )
  }

  public testSentryException(){
    const sentryExceptionRequest: ITestSentryException = {
      AdminId: this.activeAdmin.Id,
      GameId: this.gameService.activeGame().Id
    }

    this.sentryTestingService.throwSentryException(sentryExceptionRequest).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar('Causable API - Test Sentry Exception successfully thrown');
        },
        error: (res: IApiErrorResponse) => {
          if (res.Error?.ClientErrorMessages?.length > 0) {
            this.errorHandlingService.displayPageLevelErrorMessage(res, res.Error.ClientErrorMessages.toString())
          } else {
            this.errorHandlingService.displayPageLevelErrorMessage(res);
          }
        }
      }
    )
  }
}
